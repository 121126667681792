/**
 * @name: 销售管理-销售员管理接口文件
 * @author: lili
 * @date: 2023-08-04 17:46
 * @description： 销售管理-销售员管理接口文件
 * @update: 2023-08-04 17:46
 */
import {get, postJ} from "@/request"
import {IPageRes} from "@/apis/page";
import {ISalesPerson, ISalesPersonParams} from "@/apis/sale/user/types";
/**
 * 查询销售员列表
 * @param params 查询参数
 * @returns
 */
export const salesPersonQueryApi = (params: ISalesPersonParams) => get<IPageRes<ISalesPerson[]>>("/admin/salesperson/queryByPage", params)
/**
 * 创建销售员
 * @param data
 * @returns
 */
export const salesPersonCreateApi = (data: ISalesPerson) => postJ("/admin/salesperson/create", data)
/**
 * 编辑销售员
 * @param data
 * @returns
 */
export const salesPersonModifyApi = (data: ISalesPerson) => postJ("/admin/salesperson/modify", data)
/**
 * 重置密码销售员
 * @param data
 * @returns
 */
export const salesPersonResetApi = (data: ISalesPerson) => postJ("/admin/salesperson/reset", data)

/**
 * 获取销售员删除
 * @param id
 * @returns
 */
export const salesPersonRemoveApi = (id: string) => get<ISalesPerson>("/admin/salesperson/remove/" + id)
/**
 * 获取销售员详情
 * @param id
 * @returns
 */
export const salesPersonDetailApi = (id: string) => get<ISalesPerson>("/admin/salesperson/detail/" + id)
