
/**
 * @name: 销售管理-销售员管理
 * @author: lili
 * @date: 2023-08-04 17:53
 * @description： 销售管理-销售员管理
 * @update: 2023-08-04 17:53
 */
import {Component, Vue} from "vue-property-decorator"
import type {ICrudOption} from "@/types/m-ui-crud"
import {checkPhone} from "@/constants/validators";
import {Message} from "element-ui";
import {ISalesPerson, ISalesPersonParams} from "@/apis/sale/user/types";
import {
  salesPersonCreateApi,
  salesPersonDetailApi,
  salesPersonModifyApi,
  salesPersonQueryApi,
  salesPersonRemoveApi,
  salesPersonResetApi
} from "@/apis/sale/user";
import {ISystemAccount} from "@/apis/systemManage/account/types";

@Component({})
export default class saleUser extends Vue {
  // 表格加载状态
  tableLoading: boolean = true;
  // 表格数据
  tableData: any = []
  // 表格总数
  tableTotal = 0
  // 查询参数
  queryParam: ISalesPersonParams = {
    page: 1,
    limit: 10
  }
  // 表单参数
  modelForm: Partial<ISalesPerson> = {}

  /***
   * crudOption
   * 页面配置对象
   */
  crudOption: ICrudOption = {
    searchBox: true,
    addTitle: '新增销售员',
    editTitle: '编辑销售员',
    menu: true,
    editBtn: false,
    delBtn: true,
    dialogWidth: '600px',
    labelWidth:'180px',
    column: [
      {
        label: "姓名",
        prop: "name",
        align: 'center',
        search:true,
        maxlength:30,
        rules: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
        ],
        span: 24
      },
      {
        label: "账号（手机号）",
        prop: "phone",
        align: 'center',
        width: 150,
        span: 24,
        placeholder:'请输入账号',
        rules: [
          {required: true, message: '请输入账号', trigger: 'blur'},
          {trigger: 'blur', validator: checkPhone}
        ],
      },
      {
        label: "密码",
        prop: 'pwd',
        type: 'password',
        maxlength:20,
        rules: [
          {
            required: true,
            validator: (rule: any, value: any, callback: any) => {
              if (!value) {
                return callback(new Error("请输入密码"));
              } else if (!/[a-zA-Z\d]$/.test(value)) {
                callback(new Error("密码格式不正确!"));
              } else {
                callback();
              }
            },

            trigger: 'blur'
          },
        ],
        span: 24,
        hide: true,
        editHide: true,
      },
      {
        label: "状态",
        prop: "status",
        align: 'center',
        slot: true,
        value: 2,
        type: "switch",	//状态:1=启用 2=禁用
        dicData: [{label: '禁用', value: 2}, {label: '启用', value: 1}],
        span: 24,

      },
    ]
  }

  /**
   * 重置
   * @param id
   */
  handleReset(row: ISalesPerson) {
    this.$confirm('是否重置密码?', '提示', {
      confirmButtonText: '确认',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      salesPersonResetApi(row).then(e => {
        if (e) {
          Message.success("重置成功!")
          this.getList()
        }
      })
    }).catch((action) => {
    });
  }


  getList() {
    salesPersonQueryApi(this.queryParam).then(e => {
      //@ts-ignore
      this.tableData = e.list;
      this.tableTotal = e.total
    })
  }

  /**
   * 新增提交
   */
  submitSave(form: any, done: any, loading: any) {
    salesPersonCreateApi(form).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        done();
      }
    }).finally(() => {
      loading();
    })
  }

  /**
   * 删除提交
   */
  submitRemove(form: any, done: any, loading: any) {
    salesPersonRemoveApi(form.id).then(e => {
      if (e) {
        this.$message({
          type: 'success',
          message: '操作成功!'
        });
        this.getList();
        loading();
        done();
      }
    })
  }

  /**
   * 修改状态提交
   */
  handleStatus(row: ISystemAccount, val: number) {
    //@ts-ignore
    salesPersonModifyApi({id: row.id, status: val}).then(e => {
      this.$message({
        type: 'success',
        message: '操作成功!'
      });
      this.getList()
    })
  }

  async openAdd() {
    this.modelForm.status=1;
    // @ts-ignore
    this.$refs.crudRef.rowAdd()
  }

  created() {
    this.getList();
  }
}
